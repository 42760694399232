<script setup>

import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {GlobeAltIcon} from "@heroicons/vue/20/solid/index.js";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import axios from "axios";

const {locale} = useI18n({useScope: 'global'})
const emit = defineEmits(['update'])

import {countries} from '@/countries.json'

const currentCountry = computed(() => {
  return countries.find(c => c.locale === locale.value)
})

function changeCountry(country) {
  locale.value = country.locale
  localStorage.setItem('locale', country.locale)
  emit('update', country)
  axios.get(route('app.locale', country.locale), {
    headers: {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}
</script>

<template>

  <div>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm hover:bg-gray-50">
          <GlobeAltIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
          {{ currentCountry.country }}
        </MenuButton>
      </div>

      <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems
          class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1">
            <MenuItem v-slot="{ active }" v-for="country in countries">
              <button type="button" @click="changeCountry(country)"
                      :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                      class="w-full text-left flex items-center"><span class="mr-2">{{ country.icon }}</span>
                <span>{{ country.country }}</span>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<style scoped>

</style>
